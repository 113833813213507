<template>
  <div>
    <solution-header
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :call-us-btn="callUsBtn"
        :title="title"
    />
<!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`业务痛点及问题`"
               :en_title="`The Pain Points Encountered And Problems`"></Paragraph>

    <div style="width: 80%;margin-left: 10%;display: flex;flex-direction: row">
      <div style="width: 60%">
        <Titlestrlist :datalist="datalistF" style="margin-bottom: 6rem"></Titlestrlist>
      </div>
      <div style="width: 40%;margin-top: 2rem">
        <img src="@/assets/image/solution/internet/page1.png" style="width: 100%">
      </div>
    </div>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
               :title="`解决方案`"
               :en_title="`Solution`"></Paragraph>
    <div style="width: 60%;margin-left: 20%;font-size: 1.4rem;color:#228BFC;
                line-height: 2rem;
                font-weight: bold;text-align: left">
      <p>方案实现了基于身份识别的安全租赁的业务流程。包括客户移动端、应用系统、业主移动端以及</p>
      <p>IoT设备接入和区块链的平台支持等组成部分。</p>
    </div>
    <div style="width: 80%;margin-left: 10%;margin-top: 4rem;
    display: flex;flex-direction: row;justify-content: center;align-items: center">
        <div style="width: 25%;border: 2px dashed #228BFC;display: flex;flex-direction: column;
                    justify-content: center;align-items: center">
          <div style="background-color: #228BFC;width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
            业主移动端
          </div>
          <div style="background-color: #A6C5F6;width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
            身份识别
          </div>
          <div style="background-color: #A6C5F6;width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
            设备绑定
          </div>
          <div style="background-color: #A6C5F6;width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
            授权
          </div>
        </div>
      <div class="triangle-right" style="
              color: #228BFC;
              width: 1%;margin-left: 1%"
      ></div>
      <div style="width: 25%;border: 2px dashed #228BFC;display: flex;flex-direction: column;
                    justify-content: center;align-items: center">
        <div style="background-color: #228BFC;width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
          业主移动端
        </div>
        <div style="width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
          <span style="display: flex;align-items: center;justify-content: center;
           background-color: #A6C5F6;height: 100%;width: 45%;margin-right: 10%">
            房间管理
          </span>
          <span style="display: flex;align-items: center;justify-content: center;
           background-color: #A6C5F6;height: 100%;width: 45%;">
            租客管理
          </span>
        </div>
        <div style="width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
          <span style="display: flex;align-items: center;justify-content: center;
           background-color: #A6C5F6;height: 100%;width: 45%;margin-right: 10%">
            设备管理
          </span>
          <span style="display: flex;align-items: center;justify-content: center;
           background-color: #A6C5F6;height: 100%;width: 45%;">
            预约管理
          </span>
        </div>
        <div style="width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
          <span style="display: flex;align-items: center;justify-content: center;
           background-color: #A6C5F6;height: 100%;width: 45%;margin-right: 10%">
            授权管理
          </span>
          <span style="display: flex;align-items: center;justify-content: center;
           background-color: #A6C5F6;height: 100%;width: 45%;">
            账单管理
          </span>
        </div>
      </div>
      <div class="triangle-left" style="
              color: #228BFC;
              width: 1%;margin-right: 1%">
      </div>
      <div style="width: 25%;border: 2px dashed #228BFC;display: flex;flex-direction: column;
                    justify-content: center;align-items: center">
      <div style="background-color: #228BFC;width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
        业主移动端
      </div>
      <div style="background-color: #A6C5F6;width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
        身份识别
      </div>
      <div style="background-color: #A6C5F6;width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
        租赁预约
      </div>
      <div style="background-color: #A6C5F6;width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
        开锁
      </div>
    </div>
    </div>
    <div style="width: 80%;margin-left: 10%;margin-top: 1rem;margin-bottom: 1rem;
    display: flex;flex-direction: row;justify-content: center;align-items: center">
      <div style="width: 25%;display: flex;flex-direction: column;
                    justify-content: center;align-items: center">
        <div class="triangle-up"></div>
        <div class="triangle-down"></div>
      </div>
      <div class="" style="
              color: #228BFC;
              width: 2%;margin-right: 1%">
      </div>
      <div style="width: 25%;display: flex;flex-direction: column;
                    justify-content: center;align-items: center">
        &nbsp;
      </div>
      <div class="" style="
              color: #228BFC;
              width: 2%;margin-right: 1%">
      </div>
      <div style="width: 25%;display: flex;flex-direction: row;
                    justify-content: center;align-items: center">
        <div style="color:#228BFC;font-size: 1rem;font-weight: bold;margin-right: 0.4rem">蓝牙</div>
        <div style="display: flex;flex-direction: column;">
          <div class="triangle-up"></div>
          <div class="triangle-down"></div>
        </div>
        <div style="color:#228BFC;font-size: 1rem;
        font-weight: bold;margin-left: 0.4rem">Zigbee</div>
      </div>
    </div>
    <div style="width: 80%;margin-left: 10%;
    display: flex;flex-direction: row;justify-content: center;">
      <div style="width: 25%;display: flex;flex-direction: column;
                   align-items: center">
        <div style="background-color: #A6C5F6;width: 70%;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
          身份识别
        </div>
      </div>
      <div class="" style="
              color: #228BFC;
              width: 2%;margin-left: 1%"
      ></div>
      <div style="width: 25%;display: flex;flex-direction: column;
                    align-items: center">
        <div style="background-color: #228BFC;width: 70%;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
          IOT设备接入平台
        </div>
        <div style="color: #228BFC;font-size: 1.2rem">
          数据上链
        </div>
        <div style="background-color: #228BFC;width: 70%;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
          身份识别
        </div>
        <div style="color: #228BFC;font-size: 1.2rem">
          <p style="margin: 0;">身份信息+租赁信息</p>
          <p style="margin-top: 0.5rem;padding: 0">+开锁记录的Hash 存证</p>
        </div>
      </div>
      <div class="" style="
              color: #228BFC;
              width: 2%;margin-left: 1%"
      ></div>
      <div style="width: 25%;border: 2px dashed #228BFC;display: flex;flex-direction: column;
                    justify-content: center;align-items: center">
        <div style="background-color: #228BFC;width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
          智能门锁
        </div>
        <div style="background-color: #A6C5F6;width: 70%;margin: 1.5rem;
          display: flex;align-items: center;justify-content: center;
          color: white;height: 2.5rem;font-size: 1.4rem">
          开锁记录
        </div>
      </div>
    </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`应用场景及案例`"
               :en_title="`Application Scenarios And Cases`"></Paragraph>

    <div style="width: 60%;margin-left: 20%;font-size: 1.4rem;color:#228BFC;
                line-height: 2rem;
                font-weight: bold;text-align: left">
      <p>在中国、日本和东南亚，与各家智能锁的供应商合作，已有数以百计的客户导入了我司提供的平</p>
      <p>台解决方案，囊括了酒店、公寓、共享空间、健身房、企业办公室等各行各业。</p>
    </div>

    <div style="margin-left: 10%;width: 80%;margin-top: 4rem;margin-bottom: 4rem;
       align-items:center ;display: flex;flex-direction: column;">
      <div>
        <img src="@/assets/image/solution/internet/8.png" style="height: 4.5rem;padding: 2rem">
        <img src="@/assets/image/solution/internet/9.png" style="height: 4.5rem;padding: 2rem">
        <img src="@/assets/image/solution/internet/10.png" style="height: 4.5rem;padding: 2rem">
       </div>
      <div style="display: flex;flex-direction: row;">
          <img src="@/assets/image/solution/internet/6.png" style="height: 8rem;padding: 2rem">
        <div style="height: 4.5rem;padding: 4rem;display: flex;
        flex-direction: column;justify-content: center;font-size: 1.4rem">
          中国智能锁共享产业平台
        </div>
          <img src="@/assets/image/solution/internet/7.png" style="height: 8rem;padding: 2rem">
      </div>
      <div style="width: 70%;display: flex;flex-direction: row;font-size: 1.4rem">
        <div style="width: 30%">
          东京九段下REYADO酒店
        </div>
        <div style="width: 30%;margin-left: 2rem;margin-right: 2rem">
          大阪酒店+共享空间Yolo Base
        </div>
        <div style="width: 30%">
          九州分时租赁空间Yolo Base
        </div>
      </div>
     </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`硬件产品`"
               :en_title="`硬件产品`"></Paragraph>
    <div style="width: 60%;margin-left: 20%;font-size: 1.4rem;color:#228BFC;
                line-height: 2rem;
                font-weight: bold;">
      <p>来自日本的国际品牌</p>
    </div>
    <div style="display: flex;flex-direction: row;width: 68%;margin-left: 17%">
      <div style="display: flex;flex-direction: column;width: 45%;margin-right: 5%">
        <img src="@/assets/image/solution/internet/12.png"
             style="width: 100%;border: 1px dashed black">
       <span style="font-size: 1.4rem;margin: 2rem"> BCL XE1</span>
        <img src="@/assets/image/solution/internet/11.png"
             style="width: 100%;border: 1px dashed black">
        <span style="font-size: 1.4rem;margin: 2rem"> BCL XP1</span>
      </div>
      <div style="width: 50%">
        <img src="@/assets/image/solution/internet/13.png"
             style="width: 100%;border: 1px dashed black">
        <span style="font-size: 1.4rem;margin: 2rem"> Igloohome</span>
      </div>
    </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/star.svg`)"
               :title="`产品细节`"
               :en_title="`Hardware Products`"></Paragraph>
    <div style="width: 70%;margin-left: 15%;margin-bottom: 4rem;
    display: flex;flex-direction: column">
      <div style="width: 100%;display: flex;flex-direction: row">
        <img src="@/assets/image/solution/internet/15.png"
             style="width: 46%;border: 1px dashed black">
        <div style="width: 6%"></div>
        <img src="@/assets/image/solution/internet/16.png"
             style="width: 46%;border: 1px dashed black">
      </div>
      <div style="margin-top: 4rem;width: 100%">
        <img src="@/assets/image/solution/internet/17.png"
             style="width: 100%;border: 1px dashed black">
      </div>
    </div>
  </div>
</template>

<script>
import SolutionHeader from '@/components/common/SolutionHeader';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Titlestrlist from '@/components/chain/basic/common/titlestrlistfix';

export default {
  name: 'BaaSLeaflet',
  components: {
    SolutionHeader,
    // ImageList,
    Paragraph,
    Titlestrlist,
  },
  data() {
    return {
      datalistF: [
        { title: '>', content: '源于日本不动产市场的调查数据，伴随不动产租赁形式的变化，将会产生3兆日元（约1930亿人民币的市场）' },
        { title: '>', content: '中国的不动产租赁，预计将会迎来类似的机会。但是伴随着市场的成长，暂住人口管理、长租模式暴雷等问题也随之而来' },
        { title: '>', content: '本方案更为安全、方便地解决了不动产的预约、支付、授权、留痕的问题。将身份认证信息、开锁租住记录等，存储在安全的区块链平台，为迅速鉴定各方责任，及时取证提供可信存证。' },
      ],
      title: '区块链+物联网解决方案',
      body:
          '解决物联网数据的安全、流通、交易、共享上，通过去中心化的区块链技术，实现任意规模、任意类型物联网节点的接入，'
          + '打破数据平台壁垒。使得物联网设备商、建设方、数据拥有者、数据需求方在价值网络中可以各取所需，形成完美的物联网业务闭环。',
      image: require('@/assets/image/solution/internet/internet.svg'),
      showDetailBtn: true,
      callUsBtn: true,
      images: [
        require('@/assets/image/solution/internet/1.jpg'),
        require('@/assets/image/solution/internet/2.jpg'),
        require('@/assets/image/solution/internet/3.jpg'),
        require('@/assets/image/solution/internet/4.jpg'),
      ],
    };
  },
};
</script>

<style scoped>

.triangle-up{
  display:inline-block;
  width:0;
  height:0;
  border-left:0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
  border-bottom:1rem solid #228BFC;}
.triangle-down {
  display:inline-block;
  width:0;
  height:0;
  border-left:0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
  border-top:1rem solid #228BFC;}
.triangle-left {
  display:inline-block;
  width:0;
  height:0;
  border-top: 0.6rem solid transparent;
  border-right: 1rem solid #228BFC;
  border-bottom: 0.6rem solid transparent;}
.triangle-right{
  display:inline-block;
  width:0;
  height:0;
  border-top: 0.6rem solid transparent;
  border-left: 1rem solid #228BFC;
  border-bottom: 0.6rem solid transparent;}
</style>
