<template>
  <div style="">
    <ul style="list-style: none;padding: 0;margin-right: 2rem">
      <li v-for="item in datalist" :key="item.index"
          class="hf" style="text-align: left;display: flex;
            color: #333333;font-size: 1.4rem;
            font-weight: 400;font-family: PingFang SC Medium, PingFang SC Medium-Medium;
            letter-spacing: 0.2rem;line-height: 3rem;">
             <span style="font-weight: 400;
          color: #2285ff;font-size: 1.5rem;
          margin-right: 1rem">{{ item.title }}</span>
        {{ item.content }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'titlestrlist',
  props: ['datalist'],
  methods: {},
  created() {
    const list = document.getElementsByClassName('hf');
    for (let i = 0; i < list.length; i += 1) {
      const content = list[i].innerHTML;
      const contenlist = content.split(' ');
      console.log(contenlist);
    }
  },
};
</script>

<style scoped>

/*.hf:before {*/
/*  font-weight: 400;*/
/*  color: #2285ff;*/
/*  font-size: 1.5rem;*/
/*  margin-right: 1rem;*/
/*  content: attr(data-content);*/
/*}*/
</style>
